import { constants } from '@wsb/guac-widget-core';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../common/constants/headerTreatments';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_ALT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL, CUSTOM } =
  constants.paintJobs;

const id = 'layout28';
const imageTreatments = {
  [FILL]: 'accent-overlay',
  [FIT]: 'accent-overlay',
  [INSET]: 'category-solid',
  [BLUR]: 'accent-overlay',
  [LEGACY_BLUR]: 'accent-overlay'
};

const defaultHeaderTreatment = FILL;
const headerTreatmentsConfig = {
  imageTreatments,
  defaultHeaderTreatment
};

export default {
  id,
  name: 'kai',
  packs: {
    color: '#75978D',
    font: 'karla'
  },
  logo: {
    font: 'primary'
  },
  packCategories: {
    color: colorPackCategories.ACCENT
  },
  headerProperties: {
    alignmentOption: 'left',
    defaultLogoAlign: 'center'
  },
  headerTreatmentsConfig,
  showSlideshowTab: true,
  hasNavBackground: false,
  paintJobs: [LIGHT, LIGHT_ALT, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob: CUSTOM,
  buttons: {
    primary: {
      fill: buttons.fills.GHOST,
      shape: buttons.shapes.PILL,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.HIGHCONTRAST
    },
    secondary: {
      fill: buttons.fills.GHOST,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.HIGHCONTRAST
    },
    ...COMMON_BUTTON_CONFIG
  }
};
